<template>
  <b-row>
    <b-col
      cols="6"
      class="wrapper-kompship-register h-screen"
    >
      <b-navbar-brand class="ml-4 mt-1">
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/Komcards-logo.svg"
          width="150"
          class="cursor-pointer"
          @click="$router.push('/login')"
        />
      </b-navbar-brand>
      <b-col class="my-2">
        <div class="register-title text-black text-center">
          Kartu Debit Virtual
        </div>
        <div class="text-center mb-3 text-[#828282]">
          buat semua keperluan bisnis onlinemu
        </div>

        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-row class="justify-center">
            <b-col cols="8">
              <b-form @submit.prevent="onSubmit">
                <!-- email -->
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    :rules="{ required: true, email: true, regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ }"
                  >
                    <b-form-input
                      id="email"
                      ref="email"
                      v-model="userEmail"
                      class="bg-white"
                      :state="errors.length > 0 || submitErrors.email ? false:null"
                      required
                      style="height: 50px!important;"
                      @keypress="NoSpace($event)"
                      @blur="checkEmail"
                    />
                    <label for="email">
                      Email
                    </label>
                    <small class="text-primary"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="existingAccount === true"
                  cols="12"
                >
                  <div class="text-black text-md mb-1">
                    <span class="font-bold">Halo {{ user.fullname }},</span>
                    <br>
                    <div class="text-[#626262] text-justify">
                      Ternyata kamu udah terdaftar di layanan Komerce lainnya (Komship, dan Kompack). Untuk mendaftar Komcard kamu hanya perlu menyetujui ketentuan persyaratan dan melakukan pendaftaran.
                    </div>
                  </div>
                </b-col>

                <b-col
                  v-else-if="existingErrorAccount && existingAccount !== false"
                  cols="12"
                  class="m-auto"
                >
                  <div class="text-[#626262] text-black text-md mb-1">
                    {{ errorMessage }}
                  </div>
                  <b-button
                    variant="primary"
                    class="w-full m-auto"
                    @click="$router.push('/login')"
                  >

                    Masuk
                  </b-button>
                </b-col>

                <!-- password -->
                <b-col
                  v-else
                  cols="12"
                  class="p-0"
                >
                  <b-col class="mb-1">
                    <validation-provider
                      #default="{errors}"
                      name="Password"
                      vid="password"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="password"
                        v-model="userPassword"
                        class="bg-white"
                        :type="passwordFieldTypePassword"
                        :state="errors.length > 0 ? false:null"
                        onpaste="return false"
                        required
                        style="height: 50px!important;"
                        @input="validPassword"
                      />
                      <feather-icon
                        :icon="passwordToggleIconPassword"
                        class="icon-password"
                        @click="togglePasswordVisibilityPassword"
                      />
                      <label for="password">
                        Password
                      </label>
                      <small
                        v-if="errorCharPassword !== ''"
                        class="text-primary"
                      >
                        {{ errors[0] }}
                      </small>
                      <small
                        v-else
                        class="text-primary"
                      >
                        {{ errorCharPassword }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col class="mb-1">
                    <validation-provider
                      #default="{errors}"
                      name="Konfirmasi Password"
                      vid="password"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="confirm-password"
                        v-model="confirmPassword"
                        :class="confirmPassword !== confirmPassword ? 'is-invalid' : 'bg-white'"
                        :type="passwordFieldTypeConfirmPassword"
                        onpaste="return false"
                        :state="errors.length > 0 || submitErrors.password ? false:null"
                        required
                        style="height: 50px!important;"
                      />
                      <feather-icon
                        :icon="passwordToggleIconConfirmPassword"
                        class="icon-password"
                        @click="togglePasswordVisibilityConfirmPassword(togglePasswordVisibility)"
                      />
                      <label for="confirm-password">
                        Konfirmasi Password
                      </label>
                      <small class="text-primary">{{ errors[0] }}</small>
                    </validation-provider>

                    <b-form-group v-if="confirmPassword !== userPassword">
                      <small class="text-primary">*Pastikan konfirmasi password sama dengan password sebelumnya</small>
                    </b-form-group>
                  </b-col>
                </b-col>

                <!-- checkbox -->
                <b-col
                  v-if="existingAccount === false || existingAccount === true"
                  md="12"
                  class="my-2"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{errors}"
                      rules="required"
                    >
                      <b-form-checkbox
                        v-model="agree"
                        :state="errors.length > 0 || submitErrors.agree ? false:null"
                        required
                      >
                        <p class="text-black">
                          Dengan Mendaftar, saya setuju dengan
                          <br>
                          <span class="font-bold">Ketentuan Penggunaan</span> dan <span class="font-bold">Kebijakan privasi</span>
                        </p>
                      </b-form-checkbox>
                      <div class="text-black mt-2">
                        Sudah mempunyai akun? <span
                          class="text-[#08A0F7] cursor-pointer"
                          @click="$router.push('/login')"
                        >
                          Masuk
                        </span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit -->
                <b-col
                  v-if="existingAccount === false || existingAccount === true"
                  md="12"
                  class="m-auto"
                >
                  <div class="demo-inline-spacing">
                    <b-button
                      class="m-auto"
                      type="submit"
                      :variant="!existingAccount ? invalid || agree === false || confirmPassword !== userPassword || userPassword.length < 8 || loading ? 'secondary' : 'primary' : invalid || agree === false || loading ? 'secondary' : 'primary'"
                      block
                      :disabled="!existingAccount ? invalid || agree === false || confirmPassword !== userPassword || userPassword.length < 8 || loading : invalid || agree === false ||loading"
                    >
                      <b-spinner
                        v-if="loading"
                        small
                      />
                      Daftar
                    </b-button>
                  </div>
                </b-col>
              </b-form>
            </b-col>
          </b-row>
        </validation-observer>

      </b-col>
    </b-col>
    <b-col
      cols="6"
      class="wrapper-preview-dashboard-kompship d-flex justify-content-center align-items-center"
    >
      <b-img
        src="https://storage.googleapis.com/komerce/assets/svg/komcards-illust.svg"
      />
    </b-col>

    <!-- Mobile -->
    <b-col
      cols="12"
      class="wrapper-register-mobile px-3 py-2 bg-white h-screen"
    >
      <b-navbar-brand class="mt-1">
        <b-img
          src="https://storage.googleapis.com/komerce/assets/svg/Komcards-logo.svg"
          width="150"
        />
      </b-navbar-brand>
      <b-col class="my-2">
        <div class="register-title text-black text-center">
          Kartu Debit Virtual
        </div>
        <div class="text-center mb-3 text-[#828282]">
          buat semua keperluan bisnis onlinemu
        </div>

        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-row class="justify-center">
            <b-col
              cols="12"
              style="position: fixed !important;"
            >
              <b-form @submit.prevent="onSubmit">
                <!-- email -->
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      ref="email"
                      v-model="userEmail"
                      class="bg-white"
                      :state="errors.length > 0 || submitErrors.email ? false:null"
                      required
                      style="height: 50px!important;"
                      @keypress="NoSpace($event)"
                      @blur="checkEmail"
                    />
                    <label for="email">
                      Email
                    </label>
                    <small class="text-primary"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="existingAccount === true"
                  cols="12"
                >
                  <div class="text-black text-md mb-1">
                    <span class="font-bold">Halo {{ user.fullname }},</span>
                    <br>
                    <div class="text-[#626262] text-justify">
                      Ternyata kamu udah terdaftar di layanan Komerce lainnya (Komship, dan Kompack). Untuk mendaftar Komcard kamu hanya perlu menyetujui ketentuan persyaratan dan melakukan pendaftaran.
                    </div>
                  </div>
                </b-col>

                <b-col
                  v-else-if="existingErrorAccount && existingAccount !== false"
                  cols="12"
                >
                  <div class="text-[#626262] text-black text-md mb-1">
                    {{ errorMessage }}
                  </div>
                </b-col>

                <!-- password -->
                <b-col
                  v-else
                  cols="12"
                  class="p-0"
                >
                  <b-col class="mb-1">
                    <validation-provider
                      #default="{errors}"
                      name="Password"
                      vid="password"
                      rules="required|min:8"
                    >

                      <b-form-input
                        id="password"
                        v-model="userPassword"
                        class="bg-white"
                        :type="passwordFieldTypePassword"
                        :state="errors.length > 0 ? false:null"
                        onpaste="return false"
                        required
                        style="height: 50px!important;"
                        @input="validPassword"
                      />
                      <feather-icon
                        :icon="passwordToggleIconPassword"
                        class="icon-password"
                        @click="togglePasswordVisibilityPassword"
                      />
                      <label for="password">
                        Password
                      </label>
                      <small
                        v-if="errorCharPassword !== ''"
                        class="text-primary"
                      >
                        {{ errors[0] }}
                      </small>
                      <small
                        v-else
                        class="text-primary"
                      >
                        {{ errorCharPassword }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col class="mb-1">
                    <validation-provider
                      #default="{errors}"
                      name="Konfirmasi Password"
                      vid="password"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="confirm-password"
                        v-model="confirmPassword"
                        :class="confirmPassword !== confirmPassword ? 'is-invalid' : 'bg-white'"
                        :type="passwordFieldTypeConfirmPassword"
                        onpaste="return false"
                        :state="errors.length > 0 || submitErrors.password ? false:null"
                        required
                        style="height: 50px!important;"
                      />
                      <feather-icon
                        :icon="passwordToggleIconConfirmPassword"
                        class="icon-password"
                        @click="togglePasswordVisibilityConfirmPassword(togglePasswordVisibility)"
                      />
                      <label for="confirm-password">
                        Konfirmasi Password
                      </label>
                      <small class="text-primary">{{ errors[0] }}</small>
                    </validation-provider>

                    <b-form-group v-if="confirmPassword !== userPassword">
                      <small class="text-primary">*Pastikan konfirmasi password sama dengan password sebelumnya</small>
                    </b-form-group>
                  </b-col>
                </b-col>

                <!-- checkbox -->
                <b-col
                  v-if="existingAccount === false || existingAccount === true"
                  md="12"
                  class="my-2"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{errors}"
                      rules="required"
                    >
                      <b-form-checkbox
                        v-model="agree"
                        :state="errors.length > 0 || submitErrors.agree ? false:null"
                        required
                      >
                        <p class="text-black">
                          Dengan Mendaftar, saya setuju dengan
                          <br>
                          <span class="font-bold">Ketentuan Penggunaan</span> dan <span class="font-bold">Kebijakan privasi</span>
                        </p>
                      </b-form-checkbox>
                      <div class="text-black mt-2">
                        Sudah mempunyai akun? <span
                          class="text-[#08A0F7] cursor-pointer"
                          @click="$router.push('/login')"
                        >
                          Masuk
                        </span>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit -->
                <b-col
                  v-if="existingAccount === false || existingAccount === true"
                  md="12"
                  class="m-auto"
                >
                  <div class="demo-inline-spacing">
                    <b-button
                      class="m-auto"
                      type="submit"
                      :variant="!existingAccount ? invalid || agree === false || confirmPassword !== userPassword || userPassword.length < 8 || loading ? 'secondary' : 'primary' : invalid || agree === false || loading ? 'secondary' : 'primary'"
                      block
                      :disabled="!existingAccount ? invalid || agree === false || confirmPassword !== userPassword || userPassword.length < 8 || loading : invalid || agree === false ||loading"
                    >
                      <b-spinner
                        v-if="loading"
                        small
                      />
                      Daftar
                    </b-button>
                  </div>
                </b-col>

                <b-col
                  v-else-if="existingErrorAccount && existingAccount !== false"
                  md="12"
                  class="m-auto"
                >
                  <b-button
                    variant="primary"
                    class="w-full m-auto"
                    @click="$router.push('/login')"
                  >

                    Masuk
                  </b-button>
                </b-col>
              </b-form>
            </b-col>
          </b-row>
        </validation-observer>

      </b-col>
      <ModalExisting />
    </b-col>
  </b-row>

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  required, email, min, regex,
} from '@core/utils/validations/validations'
import { NoSpace } from '@/libs/helpers'
import Ripple from 'vue-ripple-directive'
import { newAxiosIns } from '@/libs/axios'
import ModalExisting from './ModalExisting.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ModalExisting,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      agree: false,
      fullname: '',
      userEmail: '',
      userPassword: '',
      confirmPassword: '',
      loading: false,
      submitErrors: '',
      required,
      email,
      min,
      regex,
      passwordFieldTypePassword: 'password',
      passwordFieldTypeConfirmPassword: 'password',
      errorCharPassword: '',
      existingAccount: false,
      NoSpace,
      user: {},
      errorMessage: '',
      existingErrorAccount: false,
    }
  },
  computed: {
    passwordToggleIconPassword() {
      return this.passwordFieldTypePassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirmPassword() {
      return this.passwordFieldTypeConfirmPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.focusEmail()
  },
  methods: {
    async onSubmit() {
      this.loading = true
      if (this.existingAccount) {
        const url = `/komcards/api/v1/register/existing?email=${this.userEmail}`
        await newAxiosIns.post(url)
          .then(res => {
            const { data } = res
            this.loading = false
            this.$router.push({ name: 'komcard-register-validate' })
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Berhasil',
                text: data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            }, { timeout: 2000 })
          })
          .catch(err => {
            const { message } = err.response.data
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            }, { timeout: 2000 })
            this.loading = false
          })
      } else {
        const url = `/komcards/api/v1/register?email=${this.userEmail}&password=${this.userPassword}`
        await newAxiosIns.post(url)
          .then(res => {
            const { data } = res
            this.loading = false
            this.$router.push({ name: 'komcard-register-validate' })
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Berhasil',
                text: data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            }, { timeout: 2000 })
          })
          .catch(err => {
            const { message } = err.response.data
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            }, { timeout: 2000 })
            this.loading = false
          })
      }
    },
    togglePasswordVisibilityPassword() {
      if (this.passwordFieldTypePassword === 'password') {
        this.passwordFieldTypePassword = 'text'
      } else if (this.passwordFieldTypePassword === 'text') {
        this.passwordFieldTypePassword = 'password'
      }
    },
    togglePasswordVisibilityConfirmPassword() {
      if (this.passwordFieldTypeConfirmPassword === 'password') {
        this.passwordFieldTypeConfirmPassword = 'text'
      } else if (this.passwordFieldTypeConfirmPassword === 'text') {
        this.passwordFieldTypeConfirmPassword = 'password'
      }
    },
    validPassword() {
      if (this.userPassword.length < 8) {
        this.errorCharPassword = '*Password minimal 8 karakter'
      } else {
        this.errorCharPassword = ''
      }
    },
    async checkEmail() {
      const url = `/komcards/api/v1/check-email?email=${this.userEmail}`
      if (this.userEmail !== '') {
        await newAxiosIns.post(url)
          .then(res => {
            const { data } = res
            this.existingAccount = data.existing
            this.user = data.data
          })
          .catch(err => {
            const { message } = err.response.data
            if (message === 'role user not allowed to register komcards') {
              this.$bvModal.show('modal-existing')
            }
            if (message === 'email already register as member komcards') {
              this.existingErrorAccount = true
              this.existingAccount = null
            }
            this.errorMessage = 'Akun kamu telah terdaftar di layanan Komcards. Silahkan ’Masuk’ untuk melanjutkan.'
          })
      }
    },
    focusEmail() {
      this.$refs.email.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './RegisterKomcard.scss'
</style>
